const DB_NAME = "basketsDatabase";
const DB_VERSION = 1;
const BASKET_HEADER_NAME = "basketHeader";
const BASKET_LINE_NAME = "basketLine";

let db;

function openDatabase() {
    return new Promise((resolve, reject) => {
        if (db) {
            resolve(db);
            return;
        }

        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = (event) => {
            console.error("Failed to open database:", event.target.error);
            reject(event.target.error);
        };

        request.onsuccess = (event) => {
            db = event.target.result;
            resolve(db);
        };

        request.onupgradeneeded = (event) => {
            db = event.target.result;
            if (!db.objectStoreNames.contains(BASKET_HEADER_NAME)) {
                db.createObjectStore(BASKET_HEADER_NAME, { keyPath: 'id', autoIncrement: true });
            }
            if (!db.objectStoreNames.contains(BASKET_LINE_NAME)) {
                const lineStore = db.createObjectStore(BASKET_LINE_NAME, { keyPath: ['code', 'basketHeaderId'] });
                lineStore.createIndex('basketHeaderId', 'basketHeaderId', { unique: false });
            }
        };
    });
}

function closeDatabase() {
    if (db) {
        db.close();
        db = null;
    }
}

function addBasketHeader(item) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([BASKET_HEADER_NAME], 'readwrite');
                const store = transaction.objectStore(BASKET_HEADER_NAME);
                const addRequest = store.add(item);
                addRequest.onsuccess = (event) => {
                    const headerId = event.target.result;
                    resolve(headerId);
                };
                addRequest.onerror = (event) => {
                    console.error("Error adding item:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function addBasketLine(item) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([BASKET_LINE_NAME], 'readwrite');
                const store = transaction.objectStore(BASKET_LINE_NAME);

                const key = [item.code, item.basketHeaderId];
                const getRequest = store.get(key);

                getRequest.onsuccess = (event) => {
                    const existingItem = event.target.result;

                    if (existingItem) {
                        const updatedItem = { ...existingItem, ...item };
                        const updateRequest = store.put(updatedItem);

                        updateRequest.onsuccess = () => {
                            resolve();
                        };

                        updateRequest.onerror = (event) => {
                            console.error("Error updating item:", event.target.error);
                            reject(event.target.error);
                        };
                    } else {
                        const addRequest = store.add(item);

                        addRequest.onsuccess = () => {
                            resolve();
                        };

                        addRequest.onerror = (event) => {
                            console.error("Error adding item:", event.target.error);
                            reject(event.target.error);
                        };
                    }
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function getAllBasketHeaders() {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([BASKET_HEADER_NAME], 'readonly');
                const store = transaction.objectStore(BASKET_HEADER_NAME);
                const request = store.getAll();

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onerror = (event) => {
                    console.error("Error retrieving items:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function getAllBasketLines(basketHeaderId) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([BASKET_LINE_NAME], 'readonly');
                const store = transaction.objectStore(BASKET_LINE_NAME);

                const index = store.index('basketHeaderId');
                const range = IDBKeyRange.only(basketHeaderId);
                const request = index.getAll(range);

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onerror = (event) => {
                    console.error("Error retrieving items:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function deleteBasketLine(code, basketHeaderId) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then((db) => {
                const transaction = db.transaction([BASKET_LINE_NAME], 'readwrite');
                const store = transaction.objectStore(BASKET_LINE_NAME);

                // Create a compound key with code and basketHeaderId
                const key = [code, basketHeaderId];
                const deleteRequest = store.delete(key);

                deleteRequest.onsuccess = () => {
                    resolve();
                };

                deleteRequest.onerror = (event) => {
                    console.error("Error deleting item:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function deleteBasketHeaderAndLines(basketHeaderId) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then((db) => {
                const transaction = db.transaction([BASKET_HEADER_NAME, BASKET_LINE_NAME], 'readwrite');
                const headerStore = transaction.objectStore(BASKET_HEADER_NAME);
                const lineStore = transaction.objectStore(BASKET_LINE_NAME);

                const linesIndex = lineStore.index('basketHeaderId');
                const linesRange = IDBKeyRange.only(basketHeaderId);
                const deleteLinesRequest = linesIndex.openCursor(linesRange);

                deleteLinesRequest.onsuccess = (event) => {
                    const cursor = event.target.result;

                    if (cursor) {
                        cursor.delete();
                        cursor.continue();
                    } else {
                        const deleteHeaderRequest = headerStore.delete(basketHeaderId);

                        deleteHeaderRequest.onsuccess = () => {
                            resolve();
                        };

                        deleteHeaderRequest.onerror = (event) => {
                            console.error("Error deleting header:", event.target.error);
                            reject(event.target.error);
                        };
                    }
                };

                deleteLinesRequest.onerror = (event) => {
                    console.error("Error deleting lines:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}


export { openDatabase, closeDatabase, addBasketHeader, addBasketLine, getAllBasketHeaders, getAllBasketLines, deleteBasketLine, deleteBasketHeaderAndLines };
