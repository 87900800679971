import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import userdata from './user-data'
import itemData from './item-data'
import basketData from './basket-data'
import favoriteData from './favorite-data'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-userdata': userdata,
    'app-items': itemData,
    'app-baskets': basketData,
    'app-favorites': favoriteData,
  },
  strict: process.env.DEV,
})
