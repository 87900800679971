import * as basketDB from '@/indexDB/basket.js';

export default {
    namespaced: true,
    state: {
        basketHeaders: [],
        basketHeadersCount: 0,
    },
    getters: {
        getBasketHeaders: (state) => state.basketHeaders.reverse(),
        getBasketHeadersCount: (state) => parseInt(state.basketHeadersCount),
    },
    mutations: {
        MUTATE_BASKET_HEADER(state, payload) {
            state.basketHeaders = payload;
        },
        UPDATE_ORDER_LINES(state, payload) {
            state.basketHeadersCount = payload
        },
        INCREMENT_ORDER_LINES(state) {
            state.basketHeadersCount = state.basketHeadersCount + 1;
        },
        DECREMENT_ORDER_LINES(state) {
            state.basketHeadersCount = state.basketHeadersCount - 1;
        },
    },
    actions: {

        async FILL_BASKET_HEADERS({ commit }) {
            await basketDB.openDatabase();
            let headers = await basketDB.getAllBasketHeaders();
            commit('MUTATE_BASKET_HEADER', headers);
            basketDB.closeDatabase();
        },
        async ADD_BASKET_HEADER({ dispatch, rootGetters }, basketName) {
            await basketDB.openDatabase();
            const now = new Date();
            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const year = now.getFullYear();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
            let headerId = await basketDB.addBasketHeader({ name: basketName == undefined ? rootGetters['app-userdata/getCompany'].description : basketName, time: formattedDateTime });
            await basketDB.closeDatabase();
            dispatch('FILL_BASKET_HEADERS');
            return headerId;
        },
        async ADD_BASKET_LINE(_, item) {
            await basketDB.openDatabase();
            await basketDB.addBasketLine(item);
            await basketDB.closeDatabase();
        },
        async GET_BASKET_LINES(_, basketHeaderId) {
            await basketDB.openDatabase();
            let lines = await basketDB.getAllBasketLines(basketHeaderId);
            await basketDB.closeDatabase();
            return lines;
        },
        async DELETE_BASKET_LINE(_, line) {
            await basketDB.openDatabase();
            await basketDB.deleteBasketLine(line.code, line.basketHeaderId);
            await basketDB.closeDatabase();
        },
        async DELETE_BASKET_HEADER({ dispatch }, basketHeaderId) {
            await basketDB.openDatabase();
            await basketDB.deleteBasketHeaderAndLines(basketHeaderId);
            await dispatch('FILL_BASKET_HEADERS');
            await basketDB.closeDatabase();
        },

    },
}
