const DB_NAME = "itemsDatabase";
const DB_VERSION = 1;
const ITEM_MED_NAME = "itemMED";
const ITEM_PARA_NAME = "itemPARA";

let db;

function openDatabase() {
    return new Promise((resolve, reject) => {
        if (db) {
            resolve(db);
            return;
        }

        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = (event) => {
            console.error("Failed to open database:", event.target.error);
            reject(event.target.error);
        };

        request.onsuccess = (event) => {
            db = event.target.result;
            resolve(db);
        };

        request.onupgradeneeded = (event) => {
            db = event.target.result;
            if (!db.objectStoreNames.contains(ITEM_MED_NAME)) {
                db.createObjectStore(ITEM_MED_NAME, { keyPath: 'code' });
            }
            if (!db.objectStoreNames.contains(ITEM_PARA_NAME)) {
                db.createObjectStore(ITEM_PARA_NAME, { keyPath: 'code' });
            }
        };
    });
}

function closeDatabase() {
    if (db) {
        db.close();
        db = null;
    }
}

function addItem(storeName, item) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([storeName], 'readwrite');
                const store = transaction.objectStore(storeName);

                const getRequest = store.get(item.code);

                getRequest.onsuccess = (event) => {
                    const existingItem = event.target.result;

                    if (existingItem) {
                        const updatedItem = { ...existingItem, ...item };
                        const updateRequest = store.put(updatedItem);

                        updateRequest.onsuccess = () => {
                            resolve();
                        };

                        updateRequest.onerror = (event) => {
                            console.error("Error updating item:", event.target.error);
                            reject(event.target.error);
                        };
                    } else {
                        const addRequest = store.add(item);

                        addRequest.onsuccess = () => {
                            resolve();
                        };

                        addRequest.onerror = (event) => {
                            console.error("Error adding item:", event.target.error);
                            reject(event.target.error);
                        };
                    }
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function addItems(storeName, items) {
    return Promise.all(items.map((item) => addItem(storeName, item)));
}

function getAllItems(storeName) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([storeName], 'readonly');
                const store = transaction.objectStore(storeName);
                const request = store.getAll();

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onerror = (event) => {
                    console.error("Error retrieving items:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function getItemByCode(storeName, itemCode) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([storeName], 'readonly');
                const store = transaction.objectStore(storeName);
                const request = store.get(itemCode);

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onerror = (event) => {
                    console.error("Error retrieving item:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function getItemCount(storeName) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([storeName], 'readonly');
                const store = transaction.objectStore(storeName);
                const request = store.count();

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onerror = (event) => {
                    console.error("Error counting items:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function deleteItem(storeName, itemCode) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([storeName], 'readwrite');
                const store = transaction.objectStore(storeName);
                const deleteRequest = store.delete(itemCode);

                deleteRequest.onsuccess = () => {
                    resolve();
                };

                deleteRequest.onerror = (event) => {
                    console.error("Error deleting item:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}


export { ITEM_MED_NAME, ITEM_PARA_NAME, openDatabase, closeDatabase, addItem, addItems, getAllItems, getItemByCode, getItemCount, deleteItem };
