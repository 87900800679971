import axiosIns from "@axios";
import useJwt from '@/auth/jwt/useJwt'

export default {
    namespaced: true,
    state: {
        AppActiveUser: {},
        Companies: [],
        Company: {}
    },
    getters: {
        getAppActiveUser: (state) => state.AppActiveUser,
        getCompanies: (state) => state.Companies,
        getCompany: (state) => state.Company,
    },
    mutations: {
        UPDATE_USER_INFO(state, payload) {
            if (payload.username) {
                state.AppActiveUser = payload;
                // localStorage.setItem("userInfo", JSON.stringify(state.AppActiveUser))
            } else {
                state.AppActiveUser = {};
            }
        },
        UPDATE_COMPANIES(state, payload) {
            if (payload) {
                state.Companies = payload;
            } else {
                state.Companies = [];
            }
        },
        UPDATE_COMPANY(state, payload) {
            if (payload) {
                state.Company = payload;
            } else {
                state.Company = {};
            }
        },
    },
    actions: {
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                axiosIns.post("account/logout/" + localStorage.getItem("sessionId"))
                    .then(response => {
                        commit('UPDATE_USER_INFO', {})
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error)
                    }).finally(() => {
                        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
                        localStorage.removeItem('userData');
                        localStorage.removeItem('sessionId');
                        localStorage.removeItem('company');
                        // localStorage.clear()
                    });
            })
        },
        INIT_USER_DATA({ commit }) {
            console.log("INIT_USER_DATA")
            return new Promise((resolve, reject) => {
                // axiosIns.get("account/getMyInfo/" + JSON.parse(localStorage.getItem('userData')).username)
                axiosIns.get("account/getMyInfo")
                    .then(response => {
                        // localStorage.setItem("token", response.headers.Authorization)
                        commit('UPDATE_USER_INFO', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        INIT_COMPANIES({ commit }) {
            console.log("INIT_COMPANIES")
            return new Promise((resolve, reject) => {
                axiosIns.get("company/findCompanies")
                    .then(response => {
                        commit('UPDATE_COMPANIES', response.data.companies);
                        commit('UPDATE_COMPANY', response.data.company);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
