import axios from '@axios'

import * as itemDB from '@/indexDB/item.js';
import * as orderDB from '@/indexDB/order.js';

export default {
    namespaced: true,
    state: {
        itemsMED: [],
        itemsPARA: [],
        orderLines: 0,
    },
    getters: {
        isItemsMEDFetched: (state) => state.itemsMED.length > 0,
        isItemsPARAFetched: (state) => state.itemsPARA.length > 0,
        geItemsMED: (state) => state.itemsMED,
        geItemsPARA: (state) => state.itemsPARA,
        geItems: (state) => state.itemsPARA.concat(state.itemsMED),
        geItemMEDByCode: (state) => (code) => {
            return state.itemsMED.find((item) => item.code === code);
        },
        geItemPARAByCode: (state) => (code) => {
            return state.itemsPARA.find((item) => item.code === code);
        },
        geItemByCode: (state) => (code) => {
            let item = state.itemsMED.find((item) => item.code === code);
            if (item == undefined)
                return state.itemsPARA.find((item) => item.code === code);
            else return item;
        },
        getOrderLines: (state) => parseInt(state.orderLines),
    },
    mutations: {
        MUTATE_ITEMS_MED(state, payload) {
            state.itemsMED = payload;
        },
        MUTATE_ITEMS_PARA(state, payload) {
            state.itemsPARA = payload;
        },
        UPDATE_ORDER_LINES(state, payload) {
            state.orderLines = payload
        },
        INCREMENT_ORDER_LINES(state) {
            state.orderLines = state.orderLines + 1;
        },
        DECREMENT_ORDER_LINES(state) {
            state.orderLines = state.orderLines - 1;
        },
    },
    actions: {
        async FETCH_ITEMS_FROM_BACKEND({ dispatch }) {
            console.log("FETCH_ITEMS_FROM_BACKEND");
            try {
                const response = await axios.get("_item/" + localStorage.getItem("lastItemUpdate"));
                console.log(response.data.length);
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        await itemDB.openDatabase();
                        await Promise.all(response.data.map(async (element) => {
                            if (element.type == "MED") {
                                await itemDB.addItem(itemDB.ITEM_MED_NAME, element);
                                localStorage.setItem("lastItemUpdate", element.updatedAt);
                            } else {
                                await itemDB.addItem(itemDB.ITEM_PARA_NAME, element);
                                localStorage.setItem("lastItemUpdate", element.updatedAt);
                            }
                        }));
                    }
                }
                await dispatch('INITIALIZE_ITEMS_MED');
                await dispatch('INITIALIZE_ITEMS_PARA');
                itemDB.closeDatabase();
            } catch (error) {
                console.error(error);
            }
        },

        async FETCH_ITEMS_FROM_BACKEND_WITH_PERCENTAGE({ dispatch }, updateProgress) {
            console.log("FETCH_ITEMS_FROM_BACKEND");
            try {
                const response = await axios.get("_item/" + localStorage.getItem("lastItemUpdate"));
                console.log(response.data.length);
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        await itemDB.openDatabase();
                        let totalItems = response.data.length;
                        let processedItems = 0;
                        await Promise.all(response.data.map(async (element) => {
                            if (element.type == "MED") {
                                await itemDB.addItem(itemDB.ITEM_MED_NAME, element);
                            } else {
                                await itemDB.addItem(itemDB.ITEM_PARA_NAME, element);
                            }
                            processedItems++;
                            updateProgress((processedItems / totalItems) * 100);
                            localStorage.setItem("lastItemUpdate", element.updatedAt);
                        }));
                    }
                }
                await dispatch('INITIALIZE_ITEMS_MED');
                await dispatch('INITIALIZE_ITEMS_PARA');
                itemDB.closeDatabase();
            } catch (error) {
                console.error(error);
            }
        },

        async FETCH_BLOQUED_ITEMS() {
            console.log("FETCH_BLOQUED_ITEMS");
            try {
                //* Sync Bloqued Items */
                const lastSyncDate = localStorage.getItem('lastBlockedSync');
                const lastSyncMonth = lastSyncDate ? new Date(lastSyncDate).getMonth() : null;
                const currentMonth = new Date().getMonth();

                // Check if the last sync was not this month
                if (lastSyncMonth === null || lastSyncMonth !== currentMonth) {
                    // Call your API to sync the blocked items
                    const response = await axios.get("_item/bloqued");
                    // await itemDB.openDatabase();
                    response.data.forEach(async element => {
                        await itemDB.deleteItem(itemDB.ITEM_MED_NAME, element);
                        await itemDB.deleteItem(itemDB.ITEM_PARA_NAME, element);
                    });
                    // itemDB.closeDatabase();
                    // console.log(response);
                    // Update 'lastBlockedSync' in localStorage
                    const today = new Date().toISOString().slice(0, 10);
                    localStorage.setItem('lastBlockedSync', today);
                }
                //* Sync Bloqued Items */
            } catch (error) {
                console.error(error);
            }
        },

        async FETCH_ITEMS_FROM_BACKEND_ADMIN({ dispatch }) {
            console.log("FETCH_ITEMS_FROM_BACKEND_ADMIN");
            try {
                const response = await axios.get("_item/forAdmin/" + localStorage.getItem("lastItemUpdate"));
                console.log(response.data.length);
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        await itemDB.openDatabase();
                        await Promise.all(response.data.map(async (element) => {
                            if (element.type == "MED") {
                                await itemDB.addItem(itemDB.ITEM_MED_NAME, element);
                                localStorage.setItem("lastItemUpdate", element.updatedAt);
                            } else {
                                await itemDB.addItem(itemDB.ITEM_PARA_NAME, element);
                                localStorage.setItem("lastItemUpdate", element.updatedAt);
                            }
                        }));
                    }
                }
                await dispatch('INITIALIZE_ITEMS_MED');
                await dispatch('INITIALIZE_ITEMS_PARA');
                itemDB.closeDatabase();
            } catch (error) {
                console.error(error);
            }
        },

        async INITIALIZE_ITEMS_MED({ commit }) {
            console.log("INITIALIZE_ITEMS_MED");
            let items = await itemDB.getAllItems(itemDB.ITEM_MED_NAME);
            commit('MUTATE_ITEMS_MED', items);
        },
        async INITIALIZE_ITEMS_PARA({ commit }) {
            console.log("INITIALIZE_ITEMS_PARA");
            let items = await itemDB.getAllItems(itemDB.ITEM_PARA_NAME);
            commit('MUTATE_ITEMS_PARA', items);
        },

        GET_INVENTORY({ dispatch }, payload) {
            return new Promise((resolve, reject) => {
                axios.get('_item/inventory/' + payload.code + '/' + parseInt(payload.Qte)).then(resp => {
                    // console.log(resp);
                    if (resp.status == 200) {
                        if (resp.data.status == 200) {
                            let inventory = resp.data.result;
                            let respStruct = {
                                title: "",
                                icon: "",
                                text: "",
                                variant: "",
                                promotion: "",
                                price: "",
                                price_ttc: "",
                                public_price: "",
                                packaging: "",
                                dlc: "",
                            };
                            respStruct.price = inventory.Unit_Price;
                            respStruct.price_ttc = inventory.Price_Including_VAT;
                            respStruct.public_price = inventory.Public_Price;
                            respStruct.packaging = inventory.Packaging;
                            respStruct.dlc = inventory.DLC;
                            if (inventory.Quotas_Item_Exist) {
                                if (payload.Qte <= parseInt(inventory.Quotas_Item_by_Customer) && payload.Qte <= parseInt(inventory.Quantity_available)) {
                                    respStruct.title = "Quantité réservée";
                                    respStruct.icon = "CheckIcon";
                                    respStruct.variant = "success";
                                } else {
                                    respStruct.title = "Indisponible en stock !";
                                    respStruct.icon = "XOctagonIcon";
                                    respStruct.text = "Aucun stock disponible pour l’article  «" + inventory.Description + "»";
                                    respStruct.variant = "danger";
                                }
                            } else {
                                if (parseInt(inventory.Quantity_available) <= 0) {
                                    respStruct.title = "Indisponible en stock !";
                                    respStruct.icon = "XOctagonIcon";
                                    respStruct.text = "Aucun stock disponible pour l’article  «" + inventory.Description + "»";
                                    respStruct.variant = "danger";
                                } else if (payload.Qte > parseInt(inventory.Quantity_available)) {
                                    respStruct.title = "Stock insuffisant !";
                                    respStruct.icon = "AlertOctagonIcon";
                                    respStruct.text = "Impossible de satisfaire la quantité demandée pour l’article «" + inventory.Description + "»";
                                    respStruct.variant = "danger";
                                } else if (inventory.Promotion_Inventory > 0) {
                                    if (parseInt(inventory.Sales_threshold) > payload.Qte) {
                                        respStruct.title = "Promotion !";
                                        respStruct.icon = "GiftIcon";
                                        respStruct.text = "Une promotion disponible pour l’article «" +
                                            payload.Qte +
                                            "», changer la quantité pour pouvoir bénéficier de la promotion : " +
                                            inventory.Sales_threshold +
                                            " -> " +
                                            inventory.Free_Sales_Qty;
                                        respStruct.variant = "info";
                                        respStruct.promotion = inventory.Sales_threshold + " -> " + inventory.Free_Sales_Qty;
                                    } else {
                                        var unitesGratuits = (payload.Qte / inventory.Sales_threshold) * inventory.Free_Sales_Qty;
                                        if (unitesGratuits > inventory.Promotion_Inventory) {
                                            unitesGratuits = inventory.Promotion_Inventory;
                                        }
                                        respStruct.title = "Promotion !";
                                        respStruct.icon = "GiftIcon";
                                        respStruct.text = "Vous avez bénéficié de " + Math.floor(unitesGratuits) +
                                            " Unitée(s) gratuite(s) «" + inventory.Sales_threshold +
                                            " -> " + inventory.Free_Sales_Qty + "»";
                                        respStruct.variant = "info";
                                        respStruct.promotion = "+" + Math.floor(unitesGratuits);
                                    }
                                } else {
                                    respStruct.title = "Quantité réservée";
                                    respStruct.icon = "CheckIcon";
                                    // respStruct.text = "Impossible de satisfaire la quantité demandée pour l’article  «" + response.data.Description + "».";
                                    respStruct.variant = "success";
                                }
                            }
                            dispatch('SAVE_ORDER', { respStruct: respStruct, item: payload });
                            resolve(respStruct);
                        } else {
                            reject({
                                title: "Article Actuellement Indisponible",
                                text: resp.data.error,
                                icon: "XOctagonIcon",
                                variant: "danger",
                            });
                        }
                    } else {
                        reject(resp.data.error)
                    }
                }).catch(error => {
                    console.log(error);
                    reject(error)
                });
            });
        },

        async SAVE_ORDER({ dispatch }, obj) {
            // console.log(obj);
            // if (!["warning", "danger"].includes(obj.respStruct.variant)) {
            obj.item["state"] = !["warning", "danger"].includes(obj.respStruct.variant);
            obj.item["promotion"] = obj.respStruct.promotion;
            obj.item["price"] = obj.respStruct.price;
            // obj.item["priceHT"] = obj.respStruct.price;
            obj.item["price_ttc"] = obj.respStruct.price_ttc;
            // obj.item["priceTTC"] = obj.respStruct.price_ttc;
            obj.item["public_price"] = obj.respStruct.public_price;
            obj.item["dlc"] = obj.respStruct.dlc;
            obj.item["packaging"] = obj.respStruct.packaging;
            // obj.item["pricePublic"] = obj.respStruct.public_price;
            obj.item["company"] = localStorage.getItem('company');
            await orderDB.openDatabase();
            await orderDB.addOrder(obj.item);
            await orderDB.closeDatabase();
            // console.log(obj.item);
            // commit('INCREMENT_ORDER_LINES');
            dispatch('COUNT_ORDER_LINES');
            // }
        },

        async SAVE_ORDER_({ dispatch }, obj) {
            obj["company"] = localStorage.getItem('company');
            await orderDB.openDatabase();
            await orderDB.addOrder(obj);
            await orderDB.closeDatabase();
            dispatch('COUNT_ORDER_LINES');
        },

        async FETCH_ORDERS() {
            await orderDB.openDatabase();
            let orders = await orderDB.getAllOrders();
            await orderDB.closeDatabase();
            return orders.filter(item => item.company == localStorage.getItem("company"));
        },

        async DELETE_ORDER_LINE({ dispatch }, code) {
            await orderDB.openDatabase();
            await orderDB.deleteByCode(code, localStorage.getItem("company"));
            await orderDB.closeDatabase();
            // commit('DECREMENT_ORDER_LINES');
            dispatch('COUNT_ORDER_LINES');
        },

        async COUNT_ORDER_LINES({ commit }) {
            let count = await orderDB.getOrderCount();
            commit('UPDATE_ORDER_LINES', count);
        },
    },
}
