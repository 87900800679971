export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/website/Home.vue'),
    meta: {
      layout: 'website',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/website/About.vue'),
    meta: {
      layout: 'website',
    },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/website/Services.vue'),
    meta: {
      layout: 'website',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/website/Contact.vue'),
    meta: {
      layout: 'website',
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/website/News.vue'),
    meta: {
      layout: 'website',
    },
  },
  {
    path: '/news/:id',
    name: 'news-details',
    component: () => import('@/views/website/NewsDetails.vue'),
    meta: {
      layout: 'website',
    },
  },
]