export default [
    {
        path: '/administrator/dashboard',
        name: 'admin-dashboard',
        component: () => import('@/views/administrator/Dashboard.vue'),
        meta: {
            resource: 'admin-dashboard',
            action: 'page',
        },
    },
    {
        path: '/administrator/customers-list',
        name: 'admin-customers-list',
        component: () => import('@/views/administrator/users/Customer.vue'),
        meta: {
            resource: 'admin-customers-list',
            action: 'page',
        },
    },
    {
        path: '/administrator/supplier-list',
        name: 'admin-suppliers-list',
        component: () => import('@/views/administrator/users/Supplier.vue'),
        meta: {
            resource: 'admin-suppliers-list',
            action: 'page',
        },
    },
    // {
    //     path: '/administrator/administrators-list',
    //     name: 'admin-administrators-list',
    //     component: () => import('@/views/administrator/users/Administrator.vue'),
    //     meta: {
    //         resource: 'admin-administrators-list',
    //         action: 'page',
    //     },
    // },
    {
        path: '/administrator/orders-list',
        name: 'admin-orders-list',
        component: () => import('@/views/administrator/orders/OrdersList.vue'),
        meta: {
            action: 'page',
            resource: 'admin-orders-list'
        },
    },
    {
        path: '/administrator/connection-tracking',
        name: 'admin-connection-tracking',
        component: () => import('@/views/administrator/ConnectionTracking.vue'),
        meta: {
            action: 'page',
            resource: 'admin-connection-tracking'
        },
    },
    {
        path: '/administrator/claims',
        name: 'admin-claims',
        component: () => import('@/views/administrator/Claims.vue'),
        meta: {
            action: 'page',
            resource: 'admin-claims'
        },
    },
    {
        path: '/customer/:slug',
        name: 'customer-dashboard',
        component: () => import('@/views/administrator/CustomerDashboard.vue'),
        meta: {
          action: 'page',
          resource: 'admin-customer-dashboard',
        },
      },
]
