export default [
    {
        path: '/supplier/dashboard',
        name: 'supplier-dashboard',
        component: () => import('@/views/supplier/Dashboard.vue'),
        meta: {
            resource: 'supplier-dashboard',
            action: 'page',
        },
    },
    {
        path: '/supplier/stock-availibility',
        name: 'supplier-stock-availibility',
        component: () => import('@/views/supplier/StockAvailibility.vue'),
        meta: {
            resource: 'supplier-stock-availibility',
            action: 'page',
        },
    },
    {
        path: '/supplier/statistics',
        name: 'supplier-statistics',
        component: () => import('@/views/supplier/StatList.vue'),
        meta: {
            resource: 'supplier-statistics',
            action: 'page',
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
        },
    },
    {
        path: '/supplier/item-statistics/:no',
        name: 'supplier-item-statistics',
        component: () => import('@/views/supplier/StatDetails.vue'),
        meta: {
            action: 'page',
            resource: 'supplier-item-statistics'
        },
    },
    {
        path: '/supplier/items-catalog',
        name: 'supplier-items-catalog',
        component: () => import('@/views/supplier/Catalog.vue'),
        meta: {
            resource: 'supplier-items-catalog',
            action: 'page',
            contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',
        },
    },
    {
        path: '/supplier/items-list',
        name: 'supplier-items-list',
        component: () => import('@/views/supplier/ItemsList.vue'),
        meta: {
            action: 'page',
            resource: 'supplier-items-list'
        },
    },
    {
        path: '/supplier/item/:code',
        name: 'supplier-item-sheet',
        component: () => import('@/views/supplier/ItemSheet.vue'),
        meta: {
            resource: 'supplier-item-sheet',
            action: 'page',
        },
    },
]
