import * as favoritetDB from '@/indexDB/favorite.js';

export default {
    namespaced: true,
    state: {
        favorites: [],
    },
    getters: {
        getFavorites: (state) => state.favorites.reverse(),
    },
    mutations: {
        MUTATE_FAVORITES(state, payload) {
            state.favorites = payload;
        },
    },
    actions: {
        async FILL_FAVORITES({ commit }) {
            await favoritetDB.openDatabase();
            let favorites = await favoritetDB.findAll();
            commit('MUTATE_FAVORITES', favorites);
            favoritetDB.closeDatabase();
        },
        async ADD_FAVORITE({ dispatch }, item) {
            await favoritetDB.openDatabase();
            await favoritetDB.addFavorite(item);
            await favoritetDB.closeDatabase();
            dispatch('FILL_FAVORITES');
        },
        async DELETE_FAVORITE({ dispatch }, line) {
            await favoritetDB.openDatabase();
            await favoritetDB.deleteFavorite(line.code);
            await favoritetDB.closeDatabase();
            dispatch('FILL_FAVORITES');
        },
        async FIND_FAVORITE(_, code) {
            await favoritetDB.openDatabase();
            let fav = await favoritetDB.findByCode(code);
            await favoritetDB.closeDatabase();
            return fav;
        },

    },
}
