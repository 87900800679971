const DB_NAME = "ordersDatabase";
const DB_VERSION = 1;
const ORDER_NAME = "order";

let db;

function openDatabase() {
    return new Promise((resolve, reject) => {
        if (db) {
            resolve(db);
            return;
        }

        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = (event) => {
            console.error("Failed to open database:", event.target.error);
            reject(event.target.error);
        };

        request.onsuccess = (event) => {
            db = event.target.result;
            resolve(db);
        };

        request.onupgradeneeded = (event) => {
            db = event.target.result;
            if (!db.objectStoreNames.contains(ORDER_NAME)) {
                db.createObjectStore(ORDER_NAME, { keyPath: ['company', 'code'] });
            }
        };
    });
}

function closeDatabase() {
    if (db) {
        db.close();
        db = null;
    }
}

function addOrder(item) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([ORDER_NAME], 'readwrite');
                const store = transaction.objectStore(ORDER_NAME);

                const compoundKey = [localStorage.getItem('company'), item.code]; // Define the compound key as an array

                const getRequest = store.get(IDBKeyRange.only(compoundKey));

                getRequest.onsuccess = (event) => {
                    const existingItem = event.target.result;

                    if (existingItem) {
                        const updatedItem = { ...existingItem, ...item };
                        const updateRequest = store.put(updatedItem);

                        updateRequest.onsuccess = () => {
                            resolve();
                        };

                        updateRequest.onerror = (event) => {
                            console.error("Error updating item:", event.target.error);
                            reject(event.target.error);
                        };
                    } else {
                        const addRequest = store.add(item);

                        addRequest.onsuccess = () => {
                            resolve();
                        };

                        addRequest.onerror = (event) => {
                            console.error("Error adding item:", event.target.error);
                            reject(event.target.error);
                        };
                    }
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function addOrders(items) {
    return Promise.all(items.map((item) => addOrder(ORDER_NAME, item)));
}

function getAllOrders() {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([ORDER_NAME], 'readonly');
                const store = transaction.objectStore(ORDER_NAME);
                const request = store.getAll();

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onerror = (event) => {
                    console.error("Error retrieving items:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

// function getByCompany(company) {
//     return new Promise((resolve, reject) => {
//         openDatabase()
//             .then(() => {
//                 const transaction = db.transaction([ORDER_NAME], 'readonly');
//                 const store = transaction.objectStore(ORDER_NAME);
//                 const index = store.index('company');

//                 const range = IDBKeyRange.only(company);
//                 const request = index.getAll(range);

//                 request.onsuccess = (event) => {
//                     resolve(event.target.result);
//                 };

//                 request.onerror = (event) => {
//                     console.error("Error retrieving items by company:", event.target.error);
//                     reject(event.target.error);
//                 };
//             })
//             .catch((error) => {
//                 console.error("Error opening database:", error);
//                 reject(error);
//             });
//     });
// }

function getOrderByCode(itemCode) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([ORDER_NAME], 'readonly');
                const store = transaction.objectStore(ORDER_NAME);
                const request = store.get(itemCode);

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onerror = (event) => {
                    console.error("Error retrieving item:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function deleteByCode(code, company) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([ORDER_NAME], 'readwrite');
                const store = transaction.objectStore(ORDER_NAME);
                const compoundKey = [company, code]; // Define the compound key as an array

                const request = store.delete(IDBKeyRange.only(compoundKey)); // Use IDBKeyRange.only to match the compound key

                request.onsuccess = () => {
                    resolve();
                };

                request.onerror = (event) => {
                    console.error("Error deleting items by code:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function getOrderCount() {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([ORDER_NAME], 'readonly');
                const store = transaction.objectStore(ORDER_NAME);
                const request = store.count();

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onerror = (event) => {
                    console.error("Error counting items:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

export { openDatabase, closeDatabase, addOrder, addOrders, getAllOrders, getOrderByCode, deleteByCode, getOrderCount };
