const DB_NAME = "favoritesDatabase";
const DB_VERSION = 1;
const FVORITE_NAME = "favorite";

let db;

function openDatabase() {
    return new Promise((resolve, reject) => {
        if (db) {
            resolve(db);
            return;
        }

        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = (event) => {
            console.error("Failed to open database:", event.target.error);
            reject(event.target.error);
        };

        request.onsuccess = (event) => {
            db = event.target.result;
            resolve(db);
        };

        request.onupgradeneeded = (event) => {
            db = event.target.result;
            if (!db.objectStoreNames.contains(FVORITE_NAME)) {
                db.createObjectStore(FVORITE_NAME, { keyPath: 'code' });
            }
        };
    });
}

function closeDatabase() {
    if (db) {
        db.close();
        db = null;
    }
}

function addFavorite(item) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([FVORITE_NAME], 'readwrite');
                const store = transaction.objectStore(FVORITE_NAME);
                const addRequest = store.add(item);
                addRequest.onsuccess = () => {
                    resolve();
                };
                addRequest.onerror = (event) => {
                    console.error("Error adding item:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function deleteFavorite(code) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([FVORITE_NAME], 'readwrite');
                const store = transaction.objectStore(FVORITE_NAME);

                const request = store.delete(code); // Use IDBKeyRange.only to match the compound key


                request.onsuccess = () => {
                    resolve();
                };

                request.onerror = (event) => {
                    console.error("Error deleting items by code:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function findAll() {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([FVORITE_NAME], 'readonly');
                const store = transaction.objectStore(FVORITE_NAME);
                const request = store.getAll();

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onerror = (event) => {
                    console.error("Error retrieving items:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

function findByCode(code) {
    return new Promise((resolve, reject) => {
        openDatabase()
            .then(() => {
                const transaction = db.transaction([FVORITE_NAME], 'readonly');
                const store = transaction.objectStore(FVORITE_NAME);
                const request = store.get(code);

                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };

                request.onerror = (event) => {
                    console.error("Error retrieving item:", event.target.error);
                    reject(event.target.error);
                };
            })
            .catch((error) => {
                console.error("Error opening database:", error);
                reject(error);
            });
    });
}

export { openDatabase, closeDatabase, addFavorite, deleteFavorite, findAll, findByCode };
