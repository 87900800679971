import Vue from 'vue'
import Toast from 'vue-toastification'

// Toast Notification Component Styles
import "vue-toastification/dist/index.css";
import '@core/scss/vue/libs/toastification.scss'

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
// Vue.use(Toast, {
//   icon: false,
//   position: "top-right",
//   timeout: 100000,
//   closeOnClick: true,
//   pauseOnFocusLoss: false,
//   pauseOnHover: true,
//   draggable: true,
//   draggablePercent: 2,
//   showCloseButtonOnHover: true,
//   hideProgressBar: true,
//   closeButton: "button",
//   transition: "Vue-Toastification__bounce",
// })

Vue.use(Toast, {
  icon: false,
  transition: 'Vue-Toastification__bounce',
  position: "top-right",
  timeout: 8000,
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 2,
  hideProgressBar: false,
  maxToasts: 20,
  newestOnTop: true
})
